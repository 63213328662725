import ophan from 'ophan/ng';
import config from '../../../../lib/config';
export const submitComponentEvent = (componentEvent) => {
    ophan.record({ componentEvent });
};
export const submitInsertEvent = (componentEvent) => submitComponentEvent({
    ...componentEvent,
    action: 'INSERT',
});
export const submitViewEvent = (componentEvent) => submitComponentEvent({
    ...componentEvent,
    action: 'VIEW',
});
export const submitClickEvent = (componentEvent) => submitComponentEvent({
    ...componentEvent,
    action: 'CLICK',
});
export const addReferrerData = (acquisitionData) => 
// Note: the current page is the referrer data in the context of the acquisition.
({
    ...acquisitionData,
    referrerPageviewId: config.get('ophan.pageViewId'),
    referrerUrl: window.location.href.split('?')[0],
});
