/**
 * WARNING!
 * Commercial client side code has moved to: https://github.com/guardian/commercial
 * This file should be considered deprecated and only exists for legacy 'hosted' pages
 */
import { isAdBlockInUse } from '@guardian/commercial';
export const init = () => {
    isAdBlockInUse().then((blockerDetected) => {
        const adBlockers = window.guardian.adBlockers;
        adBlockers.active = blockerDetected;
        // Run each listener
        runEachListener(adBlockers.onDetect);
        // If subsequent listeners are added to the queue, they should be run immediately
        adBlockers.onDetect.push = function (...args) {
            // push the function or functions onto the queue
            const arrayLen = Array.prototype.push.call(adBlockers.onDetect, ...args);
            // then execute them
            runEachListener(args);
            return arrayLen;
        };
        function runEachListener(listeners) {
            listeners.forEach(function (listener) {
                try {
                    listener(blockerDetected);
                }
                catch (e) {
                    console.log(e);
                }
            });
        }
    }, (error) => {
        console.log(error);
    });
};
